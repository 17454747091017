.hero-image {
  filter: brightness(1.1);
  object-fit: cover;
  object-position: bottom;
}

.text {
  color: white;
  text-align: left;
  padding: 100px;
}

.main-text {
  width: 20vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.main-img {
  width: 20vw;
  height: 90vh;
  background-image: url(../Img/Main/318845366_2292511107593971_9166420664710304606_n.png);
  background-size: auto;
  background-repeat: no-repeat;
}

.section {
  width: 30vw;
  height: 30vh;
  background-color: rgb(189, 189, 221);
}

.section-container {
  height: 55vh;
  width: 90vw;
  justify-content: space-between;
  position: absolute;
  right: 11vh;
  background-color: white;
}

.section-container li {
  height: 50vh;
  width: 20vw;
  background-color: white;
  border: solid 1px;
  border-radius: 30px;
}

.section-container li:hover{
  background-color: greenyellow;
}