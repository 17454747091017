/* @font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Thin.woff2) format('woff2');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-ThinItalic.woff2) format('woff2');
  font-style: italic;
  font-weight: 200;
  font-display: swap;
} */

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Light.woff2) format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-LightItalic.woff2) format('woff2');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Regular.woff2) format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Italic.woff2) format('woff2');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Medium.woff2) format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-MediumItalic.woff2) format('woff2');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Bold.woff2) format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-BoldItalic.woff2) format('woff2');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

/* @font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-Black.woff2) format('woff2');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url(../Fonts/mardoto/Mardoto-BlackItalic.woff2) format('woff2');
  font-style: italic;
  font-weight: 800;
  font-display: swap;
} */


body {
  @apply bg-primary-complement tracking-wider;
  font-family: Mardoto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.primary-duotone {
  position: relative;
}

.primary-duotone::before,
.primary-duotone::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
}

.primary-duotone img {
  filter: brightness(105%);
}

.primary-duotone::before {
  @apply bg-primary/20;
  mix-blend-mode: lighten;
}
